import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import gsap, { Expo } from 'gsap';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import PublishedArtWalkKyoto from '../../components/published-art-walk-kyoto';
import PublishedNishiri from '../../components/published-nishiri';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/works/work.module.scss';
import MainVisualSp from '../../images/works/kyoto-404/main_visual_sp.jpg';
import MainVisualPc from '../../images/works/kyoto-404/main_visual_pc.jpg';

class Kyoto404 extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const img = document.querySelectorAll('.init_a_img');
    setTimeout(() => {
      gsap.to(img, {
        duration: 1.5,
        scale: 1,
        ease: Expo.easeOut,
      });
    }, 1000);
    const header = document.querySelector('header');
    const visual = document.querySelector('.visual');
    const visualHeight = visual.clientHeight - 64;
    window.addEventListener('scroll', () => { this.changeHeader(header, visualHeight); });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => { this.changeHeader(); });
  }

  changeHeader = (e, height) => {
    if (window.pageYOffset > height) {
      e.classList.add('work');
    } else {
      e.classList.remove('work');
    }
  };

  details = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const detailsItem = document.querySelectorAll('.init_a_detailsItem');
      this.initAnimation.list(detailsItem);
      unobserve();
    }
  };

  challengesPoint = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isChallengesPoint = 'ChallengesPoint';
      const headingChallengesPoint = document.querySelector('.init_a_headingChallengesPoint');
      const challengesPointItem = document.querySelectorAll('.init_a_challengesPointItem');
      this.initAnimation.heading(headingChallengesPoint, isChallengesPoint);
      this.initAnimation.list(challengesPointItem);
      unobserve();
    }
  };

  subCut1 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut1_bg');
      const subCutImg = document.querySelector('.init_a_subCut1_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut2 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut2_bg');
      const subCutImg = document.querySelector('.init_a_subCut2_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  projectTeam01 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isProjectTeam = 'ProjectTeam';
      const headingProjectTeam = document.querySelector('.init_a_headingProjectTeam');
      const projectTeamItem01 = document.querySelectorAll('.init_a_projectTeamItem01');
      this.initAnimation.heading(headingProjectTeam, isProjectTeam);
      this.initAnimation.list(projectTeamItem01);
      unobserve();
    }
  };

  projectTeam02 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const projectTeamItem02 = document.querySelectorAll('.init_a_projectTeamItem02');
      this.initAnimation.list(projectTeamItem02);
      unobserve();
    }
  };

  otherWorks = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isOtherWorks = 'OtherWorks';
      const headingOtherWorks = document.querySelector('.init_a_headingOtherWorks');
      this.initAnimation.heading(headingOtherWorks, isOtherWorks);
      unobserve();
    }
  };

  render() {
    const WorkQuery = graphql`
      {
        DeviceSp: file(relativePath: { eq: "works/kyoto-404/device_sp.png" }){
          childImageSharp {
            fluid(maxWidth: 170, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        DevicePc: file(relativePath: { eq: "works/kyoto-404/device_pc.png" }){
          childImageSharp {
            fluid(maxWidth: 715, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut1: file(relativePath: { eq: "works/kyoto-404/subcut_1.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut2: file(relativePath: { eq: "works/kyoto-404/subcut_2.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const optionsDetails = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const options = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={WorkQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header isWork transition={transition} current="works" />
              <main className={`work ${Style.item}`}>
                <div className={`visual ${Style.visual}`}>
                  <figure className={`init_a_visual ${Style.visualFigure}`}>
                    <img
                      className="spTabVDisBlock init_a_img"
                      src={MainVisualSp}
                      alt="京都四〇四"
                    />
                    <img
                      className="tabHPcDisBlock init_a_img"
                      src={MainVisualPc}
                      alt="京都四〇四"
                    />
                  </figure>
                  <figure className={Style.visualDevice}>
                    <Img
                      className="spTabVDisBlock"
                      fluid={data.DeviceSp.childImageSharp.fluid}
                      alt="京都四〇四"
                    />
                    <Img
                      className="tabHPcDisBlock"
                      fluid={data.DevicePc.childImageSharp.fluid}
                      alt="京都四〇四"
                    />
                  </figure>
                </div>
                <Observer {...optionsDetails} onChange={this.details}>
                  <section className={`details ${Style.details}`}>
                    <div className={`inner ${Style.inner}`}>
                      <div className={Style.block}>
                        <div className={Style.left}>
                          <p className={`init_a_detailsCategory_text ${Style.detailsCategory}`}>プロジェクト</p>
                          <h1 className={`init_a_detailsTitle_text ${Style.detailsTitle}`}>京都四〇四</h1>
                          <p className={`init_a_detailsItem ${Style.description}`}>出版社「千十一編集室」を主宰する編集者の影山裕樹、東京でデザイン事務所「LABORATORIES」を率いるアートディレクターの加藤賢策、京都在住のアーティスト増本泰斗とともに、地域文化のクリエイティブを研究する「京都四〇四」を立ち上げました。</p>
                          <p className={`init_a_detailsItem ${Style.link}`}>
                            <a
                              href="https://404-kyoto.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://404-kyoto.com/
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 17 14"
                              >
                                <rect x="4.5" y="0.5" width="12" height="9" />
                                <path d="M16,1v8H5V1H16 M17,0H4v10h13V0L17,0z" />
                                <rect x="0.5" y="4.5" width="12" height="9" />
                                <path d="M12,5v8H1V5H12 M13,4H0v10h13V4L13,4z" />
                              </svg>
                            </a>
                          </p>
                        </div>
                        <div className={Style.info}>
                          <dl className="init_a_detailsItem">
                            <dt>プロジェクト期間</dt>
                            <dd>2019年6月〜</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </section>
                </Observer>
                <section className="sectionContent">
                  <div className="inner">
                    <Observer {...options} onChange={this.challengesPoint}>
                      <div>
                        <h2 className="heading">
                          <span className="text textChallengesPoint">Overview</span>
                          <span className="clip clipChallengesPoint init_a_headingChallengesPoint">Overview</span>
                        </h2>
                        <p className="init_a_challengesPointItem sectionAddition">概要</p>
                        <div className={`init_a_challengesPointItem ${Style.text}`}>
                          <p>「京都四〇四」は、これからの地域文化の発展に欠かせないクリエイティブの手法を研究し、社会実装を目指すコレクティブ（運動体）です。京都内外のクリエイターやアーティストのスキルやノウハウを集約し、多様なパートナーとともに様々な事業やプロジェクトを“共創”していきます。</p>
                          <p>インターネット上の「404エラー」のように、エラーやバグは、社会を前進させるための一種の避難所（アジール）でもあります。観光客向けに開発された街並みに多くの人が集う“都会らしさ”と、人と人の信頼関係が物を言う“土着性”が同居する京都で、意図的なエラーを発生させ両者を往還するようなプロジェクトや運動を生み出していきます。</p>
                          <p>2020年1月より、広報セクション向け連続ワークショップを開催。京阪神に事務所を構える企業・自治体の広報職、出版社等のメディア企業や制作会社に勤める編集者やディレクターの育成を目的としています。</p>
                        </div>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut1}>
                      <figure className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut1_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut1_img"
                          fluid={data.subCut1.childImageSharp.fluid}
                          alt="京都四〇四"
                        />
                      </figure>
                    </Observer>
                    <Observer {...options} onChange={this.subCut2}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut2_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut2_img"
                          fluid={data.subCut2.childImageSharp.fluid}
                          alt="京都四〇四"
                        />
                      </div>
                    </Observer>
                  </div>
                </section>

                <section className={`sectionContent ${Style.projectTeam}`}>
                  <div className={`inner ${Style.inner}`}>
                    <Observer {...options} onChange={this.projectTeam01}>
                      <div>
                        <h2 className="heading">
                          <span className="text textProjectTeam">Project <br className="spDisBlock" />team</span>
                          <span className="clip clipProjectTeam init_a_headingProjectTeam">Project <br className="spDisBlock" />team</span>
                        </h2>
                        <p className="init_a_projectTeamItem01 sectionAddition">プロジェクトチームのご紹介</p>
                        <p className={`init_a_projectTeamItem01 ${Style.intro}`}>
                          競争から共創へ。
                          <br />
                          弊社では、クライアントと
                          <br className="spDisBlock" />
                          受託会社、制作会社と
                          <br />
                          外部パートナーといった
                          <br className="spDisBlock" />
                          垣根を越え、
                          <br />
                          プロジェクトごとに最適な
                          <br className="spDisBlock" />
                          チームを編成しています。
                        </p>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.projectTeam02}>
                      <div className={Style.credit}>
                        <h3 className="init_a_projectTeamItem02">プロジェクト体制</h3>
                        <div className="init_a_projectTeamItem02">
                          <dl>
                            <dt>光川 貴浩</dt>
                            <dd>合同会社バンクトゥ</dd>
                          </dl>
                          <dl>
                            <dt>影山 裕樹</dt>
                            <dd>合同会社千十一編集室</dd>
                          </dl>
                          <dl>
                            <dt>加藤 賢策</dt>
                            <dd>株式会社ラボラトリーズ</dd>
                          </dl>
                          <dl>
                            <dt>増本 泰斗</dt>
                            <dd>アーティスト、アーティスティック・マネージャー</dd>
                          </dl>
                          <dl>
                            <dt>吉田 健人</dt>
                            <dd>合同会社バンクトゥ</dd>
                          </dl>
                          <dl>
                            <dt>人見 和真</dt>
                            <dd>合同会社バンクトゥ</dd>
                          </dl>
                        </div>
                      </div>
                    </Observer>
                  </div>
                </section>
                <Observer {...options} onChange={this.otherWorks}>
                  <section className={`sectionContent ${Style.otherWorks}`}>
                    <h2 className="heading">
                      <span className="text textOtherWorks">Other <br className="spDisBlock" />works</span>
                      <span className="clip clipOtherWorks init_a_headingOtherWorks">Other <br className="spDisBlock" />works</span>
                    </h2>
                    <PublishedArtWalkKyoto transition={transition} />
                    {/* <PublishedNishiri transition={transition} /> */}
                    <p className={`btn ${Style.btn}`}>
                      <TransitionLink to="/works" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                        <span>Explore our works</span>
                      </TransitionLink>
                    </p>
                  </section>
                </Observer>
              </main>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Kyoto404;
